<template>
  <div class="pt-6 md:pt-10">
    <div class="text-center font-700" text="xl md:2xl black" m="b-2.5 md:b-6">
      {{ pageTitle }}
    </div>
    <div class="block md:flex md:px-8 md:pb-4">
      <div v-for="item in HowToEarnOptions" :key="item.icon" px="8 md:4" pb="8 md:15" class="flex flex-1 items-center md:mx-4 md:block md:text-center">
        <div
          h="6"
          w="6"
          rounded="full"
          text="base center white"
          flex="shrink-0 "
          bg="[#1B1B1B]"
          font="500"
          leading="6"
          class="md:hidden"
        >
          {{ item.icon }}
        </div>
        <img h="12 md:28" m="x-5 md:b-10" class="flex-shrink-0" :src="item.image" />
        <div text="xs #363636 md:lg md:black" font="400">
          {{ item.description() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageHowToEarn1 from '@/assets/images/home/how_to_earn_1.png'
import ImageHowToEarn2 from '@/assets/images/home/how_to_earn_2.png'
import ImageHowToEarn3 from '@/assets/images/home/how_to_earn_3.png'
import ImageHowToEarn3_p from '@/assets/images/home/how_to_earn_3_p.png'

const { t } = useI18n()
const route = useRoute()
const { isBurnCoinsScene } = useGlobalStore()
const commonDataStore = useCommonStore()

const cobrand = computed(() => {
  const { partnerInfo } = commonDataStore.commonData || {}
  return {
    logo: partnerInfo?.distLogo,
    name: partnerInfo?.distName,
  }
})

const isPartner = computed(() => Boolean(route.query.campaign_id) && (cobrand.value.logo || cobrand.value.name))

const pageTitle = isPartner.value ? `${t('key.business.howtoearn.partnerHowToEarnTitle')}` : t('key.business.howtoearn.howToEarnTitle')

const HowToEarnOptions = computed(() => [
  {
    icon: '1',
    image: ImageHowToEarn1,
    description: () => {
      if (isBurnCoinsScene) {
        return t('key.business.howtoearn.howToEarnDesc1')
      }
      return isPartner.value ? t('key.business.howtoearn.partnerHowToEarnDesc1') : t('key.business.howtoearn.howToEarnDesc1')
    },
  },
  {
    icon: '2',
    image: ImageHowToEarn2,
    description: () => {
      if (isBurnCoinsScene) {
        return t('key.business.howtoearn.burnCoinsToEarnDesc2')
      }
      return isPartner.value ? t('key.business.howtoearn.partnerHowToEarnDesc2') : t('key.business.howtoearn.howToEarnDesc2')
    },
  },
  {
    icon: '3',
    image: isPartner.value ? ImageHowToEarn3_p : ImageHowToEarn3,
    description: () => {
      if (isBurnCoinsScene) {
        return t('key.business.howtoearn.burnCoinsToEarnDesc3')
      }
      return isPartner.value ? t('key.business.howtoearn.partnerHowToEarnDesc3') : t('key.business.howtoearn.howToEarnDesc3')
    },
  },
])
</script>
