import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchClickEvent } from '~/types/event-tracking'

export function useTrackHome() {
  const gtm = useGtm()
  const filterConditionsStore = useFilterConditionsStore()
  const dayjs = useDayjs()
  const { query } = useRoute()
  const { locale } = useLocale()

  function trackSearchClickEvent() {
    const eventParams: SearchClickEvent = {
      event: ENUM_EventType.Search,
      page_category: ENUM_PageName.Home,
      city: filterConditionsStore.filterConditions.selectedCity?.destinationName,
      city_id: filterConditionsStore.filterConditions.selectedCity?.destinationId,
      country_code: filterConditionsStore.filterConditions.selectedCity?.countryCode,
      search_date: dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD'),
      locale: locale.value,
      campaign_id: query.campaign_id as string ?? '',
      campaign_name: query.campaign_name as string ?? '',
    }

    gtm?.trackEvent(eventParams)
  }

  return { trackSearchClickEvent }
}
